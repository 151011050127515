@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  position: sticky;
  top: 0;

  & nav {
    & + div {
      & ul {
        & li {
          & div:nth-child(2) {
            border-top: 0;
          }
        }
      }
    }
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  padding: 24px 24px 96px;
  @apply --marketplacePageWidth;

  @media (--viewportMedium) {
    position: relative;
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    padding: 32px 24px 48px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }
}

.searchFiltersMobile {
  padding: 0 0 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.topbarMenu {
  box-shadow: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--matterColorNegative);
}

.topFilters {
}

.breadCrumb {
  font-size: 22px;
  line-height: 1;
  margin: 0 0 24px;
  text-align: left;
}

.mainFilters {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.filterList {
  display: inline;
  margin: 0;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
  }
}

.filterItem {
  width: auto;
  height: auto;
  min-height: 0;
  margin-bottom: 12px;
  float: left;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    position: relative;
    margin-bottom: 0;
    float: initial;
  }
}

.filterHeading {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  position: relative;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  margin-right: 12px;

  /* Font */
  font-family: var(--fontFamilySecondary);
  font-size: 13px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }

  @media (--viewportMedium) {
    white-space: nowrap;
  }
}

.dropdown {
  /* Grid */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
  min-width: max-content;
  overflow-y: auto;
  max-height: 400px;
  margin-top: 12px;

  /* Position */
  position: absolute;
  left: 0;

  /* Layout */
  padding: 24px;
  z-index: 9;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowPopup);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    max-height: unset;
    overflow-y: initial;
    grid-gap: 48px;
  }
}

.dropdownItem {
  display: flex;
}

.dropdownButtonsItem {
  flex-direction: column;
}

.buttonFilter {
  margin-bottom: 12px;
  padding: 0;

  & fieldset {
    overflow: hidden;
  }

  & ul {
    height: 200px;
    overflow: auto;
  }
}

.filterSelection {
  padding: 0;
}

.activeFilterHeading {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.sizesLabel {
  font-weight: var(--fontWeightSemiBold);
  font-size: 15px;
  line-height: 24px;
  margin: 0 0 12px;
  padding: 0;
}

.noWrap {
  white-space: nowrap;
}

.footwearSizeDropdown {
  overflow: visible;

  @media (--viewportMedium) {
    overflow: initial;
  }
}

.mainCategoryLink {
  color: var(--matterColorDark);
  transition: color 0.25s ease-in-out;

  &:hover {
    color: var(--marketplaceColor);
  }
}
